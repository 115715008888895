import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,_vm._l((_vm.vagas),function(item){return _c(VCol,{key:item.id,attrs:{"cols":"12","sm":"6"}},[_c(VCard,[_c('div',{staticClass:"d-flex flex-row align-center mx-2"},[_c(VAvatar,{attrs:{"color":item.empresa_foto ? 'white' : 'secondary',"size":"40"}},[(item.empresa_foto)?_c(VImg,{attrs:{"src":item.empresa_foto,"alt":"Logo"}}):_c(VIcon,[_vm._v("mdi-world")])],1),_c(VCardTitle,{staticClass:"text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(item.nome)+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.favorite(item)}}},[_c(VIcon,[_vm._v("mdi-star-outline")])],1),_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{staticClass:"info--text",attrs:{"link":""},on:{"click":function($event){return _vm.toView(item)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"info"}},[_vm._v("mdi-eye")]),_vm._v(" Visualizar ")],1)],1),_c(VListItem,{staticClass:"error--text",attrs:{"link":""},on:{"click":function($event){return _vm.cancel(item)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-close")]),_vm._v(" Cancelar ")],1)],1)],1)],1)],1),_c(VCardText,[_vm._v(" "+_vm._s(item.descricao)+" ")]),(item.competencias)?_c(VCardText,_vm._l((item.competencias),function(competencia,i){return _c(VChip,{key:(competencia + "-" + i),staticClass:"ma-1",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(competencia)+" ")])}),1):_vm._e()],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }