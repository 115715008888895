<template>
  <v-row>
    <v-col cols="12" sm="6" v-for="item in vagas" :key="item.id">
      <v-card>
        <div class="d-flex flex-row align-center mx-2">
          <v-avatar
            :color="item.empresa_foto ? 'white' : 'secondary'"
            size="40"
          >
            <v-img
              v-if="item.empresa_foto"
              :src="item.empresa_foto"
              alt="Logo"
            ></v-img>
            <v-icon v-else>mdi-world</v-icon>
          </v-avatar>
          <v-card-title class="text-body-1 font-weight-bold">
            {{ item.nome }}
          </v-card-title>
          <v-spacer></v-spacer>

          <v-btn icon @click="favorite(item)">
            <v-icon>mdi-star-outline</v-icon>
          </v-btn>
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="info--text" link @click="toView(item)">
                <v-list-item-title>
                  <v-icon color="info">mdi-eye</v-icon>
                  Visualizar
                </v-list-item-title>
              </v-list-item>

              <v-list-item class="error--text" link @click="cancel(item)">
                <v-list-item-title>
                  <v-icon color="error">mdi-close</v-icon>
                  Cancelar
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <v-card-text>
          {{ item.descricao }}
        </v-card-text>

        <v-card-text v-if="item.competencias">
          <v-chip
            v-for="(competencia, i) in item.competencias"
            :key="`${competencia}-${i}`"
            class="ma-1"
            color="success"
            small
          >
            {{ competencia }}
          </v-chip>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CardVagas",
  methods: {
    /**
     * @description Favorita a vaga
     */
    favorite(item) {
      alert(`Vaga favoritada ${item}`);
    },
    /**
     * @descripion Visualizar a vaga
     */
    toView(item) {
      alert("Visualizar a vaga");
    },
    /**
     * @description Cancelar a vaga
     */
    cancel(item) {
      alert("Cancelar a vaga");
    },
  },
  computed: {
    vagas: {
      get() {
        return this.$store.getters["resCandidatos/getVagas"];
      },
    },
  },
};
</script>

<style></style>
